@charset "utf-8";

/* Global Styles */
@font-face {
  font-family: 'clint';
  src: url(../src/fonts/clintmarker.ttf) format('woff');
}

@font-face {
  font-family: 'avega';
  src: url(../src/fonts/avega.ttf) format('truetype');
}

@font-face {
  font-family: 'avega-italic';
  src: url(../src/fonts/Avega-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'avega-out';
  src: url(../src/fonts/avegaoutline.ttf) format('truetype');
}

@font-face {
  font-family: 'avega-out-italic';
  src: url(../src/fonts/Avega-Italic-Outline.ttf) format('truetype');
}

:root {
  --bs-blue: #00edda;
  --bs-indigo: #6610f2;
  --bs-purple: #ee00ff;
  --bs-pink: #fc00c8;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #fbed21;
  --bs-green: #03f480;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #ee00ff;
  --bs-secondary: #00edda;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #000;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0;
  --bs-border-radius-sm: 0;
  --bs-border-radius-lg: 0;
  --bs-border-radius-xl: 0;
  --bs-border-radius-2xl: 0;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;

  --exo-sh-color: #00efc7;
  --exo-gs-color: #ff9d15;
  --exo-sr-color: #ffe115;
  --exo-rv-color: #00e21b;
  --exo-db-color: #ff2c2c;
  --exo-cb-color: #ea24ff;
  --exo-ns-color: #6A3EDF;
  --exo-sm-color: #d64a3d;
  --exo-ds-color: #ffd19e;
}

.blank-space {
  width: 100%;
  display: block;
  clear: both;
  height: 2rem;
}

.mb-0 {
  margin-bottom: 0 !important;
  line-height: 1px;
}
.out-shadow-text {
  -webkit-text-stroke: 2px black;
  text-shadow: 3px 3px black;
  letter-spacing: -0.2rem;
}
.detail-title {
  font-family: Exo;
  font: weight 900px;
  font-style: italic;
  -webkit-text-stroke: 2px black;
  text-shadow: 3px 3px black;
  letter-spacing: -0.2rem;
}
/*  EXO  TEXT COLORS  */

.text-black {
  color: black;
}

.yellow-txt {
  color: var(--bs-yellow);
}

.pink-txt {
  color: var(--bs-pink);
}

.purple-txt {
  color: var(--bs-purple);
}

.white-txt {
  color: var(--bs-white);
}
.blue-txt {
  color: var(--bs-blue);

}

/*  FONTS  SETTINGS  */
.avega {
  font-family: 'avega', sans-serif;
}

.avegaol {
  font-family: 'avega-out', sans-serif;
}

.clint {
  font-family: 'clint', sans-serif;
}

button {
  border-radius: 0 !important;

}

.btn-primary {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  font-family: 'Avega';
}

.btn-primary:hover {
  background-color: #910291;
  border-color: #ee00ff;
}

.btn-outline-primary {
  border-color: var(--bs-primary);
  color: var(--bs-primary);
  font-family: 'Avega';
}

.btn-outline-primary:hover {
  border-color: var(--bs-primary);

  background-color: var(--bs-primary);
}

.btn-dark {
  background-color: black;
  border-color: black;
}

.btn-dark:hover {
  background-color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.5);
  transform: scale(105%);
}

.btn-xxlg {
  font-family: 'avega';
  font-size: large;
  padding: 0.55rem 1.25rem;
}

body {  background: #005aff;

  background-size: 100%;
   /*  background: linear-gradient(-45deg, #0040c8, #7a01ba, #1006ad, #1006ad);
background-size: 400% 400%;
 animation: gradient 5s ease infinite;*/
  font-family: 'Exo', sans-serif;

}

.body-clinic {
  background-color: #001b48;
}

.bg-clinic {
  background: url(../src/images/exobots-clinic-bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #001b48;
}

.bg-corner-dots {
  background: url(../src/images/top-corner-dots.png);
  background-size: 50%;
  background-repeat: no-repeat;
}

@media (min-width: 1300px) {
  .bg-corner-dots {
    background-size: 50%;
  }
}

@media (min-width: 2000px) {
  .bg-corner-dots {
    background-size: 30%;
  }
}

.banner-title .display-1 {
  line-height: 1;
  letter-spacing: -0.2rem;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}


a:hover {
  color: rgba(255, 255, 255, 1.00);
}

@media (min-width: 1200px) {
  .sticky-exobot {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 5px;
  }

  .fs-1 {
    font-size: 3.5rem !important;
  }

  .fs-2 {
    font-size: 3rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

/*header*/

/* Logo placeholder*/
#logo {
  float: left;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 250px;
  text-align: center;
  color: rgba(255, 255, 255, 1.00);
}

.login-but {
  border-color: yellow;
  color: yellow;
  background-color: transparent;
}

.stronghold-bg {
  background: rgb(0, 0, 255);
  background: linear-gradient(0deg, rgba(0, 0, 255, 1) 35%, rgba(0, 237, 218, 0.9) 100%);
}

.stronghold-stats-bg {
  background: rgb(0, 0, 255) !important;
}

.sumrak-bg {
  background: rgb(209, 186, 0);
  background: linear-gradient(0deg, rgba(209, 186, 0, 0.9) 37%, rgba(237, 214, 0, 0.9) 100%);
}

.sumrak-stats-bg {
  background: rgb(209, 186, 0) !important;
}

/* EXOBOTS BG COLORS*/

.sh-bg {
  background-color: var(--exo-sh-color);
}

.rv-bg {
  background-color: var(--exo-rv-color);
}

.cb-bg {
  background-color: var(--exo-cb-color);
}

.sr-bg {
  background-color: var(--exo-sr-color);
}

.gs-bg {
  background-color: var(--exo-gs-color);
}

.db-bg {
  background-color: var(--exo-db-color)
}

.ns-bg {
  background-color: var(--exo-ns-color);
}

.sm-bg {
  background-color: var(--exo-sm-color);
}

.ds-bg {
  background-color: var(--exo-ds-color);
}



/* EXOBOTS ANIMATIONS*/

/* Idle Strecht */
.idle-strech {
  animation: y-scale 1.3s ease-out normal;
  animation-iteration-count: infinite;
  margin: auto;
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;

}

@keyframes y-scale {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 1.01);
  }

  100% {
    transform: scale(1, 1);
  }
}

/* Idle floating */

.idle-float {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }

  65% {
    transform: translate(0, 15px);
  }

  to {
    transform: translate(0, -0px);
  }
}




/* EXOBOT GRID ITEM  */
.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: 0;
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: none;
  border-radius: 0;
}

.item-info {
  color: white
}

.product-price {
  color: white;
  line-height: 1;
  margin-top: 25px;
  margin-bottom: -15px
}

.product-price-dollar {
  color: rgba(255, 255, 255, 0.3);
  font-weight: 700;
}

.btn {
  font-weight: 800;
  border-radius: 0;
}

.product-card {
  background-color: #1e6fff!important;
 /* background-color: rgba(0, 0, 0, 0.3) !important;*/
  -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.324);
  -moz-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.324);
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.324);
  margin-bottom: 25px
}

.card-id {
  background-color: rgba(0, 0, 0, 0.2)
}

.info-card-label {
  font-size: 12px;
  margin-bottom: 0;
}

/* Responsive fonts-size*/
@media (min-width: 576px) and (max-width:1200px) {
  p.info-card-label {
    font-size: 10px
  }

  .stats-table {
    font-size: 12px
  }
}

@media (max-width:768px) {
  .product-price-dollar {
    margin-top: 15px
  }
}

/* 
.exo-info {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}*/

.info-card-value {
  margin-bottom: 0;
  line-height: 1;
  padding-top: 5px;
}

/*.out-of {
  color: rgba(255, 255, 255, 0.3);
}*/

.stats-bg {
  background: rgb(0, 0, 0);
  /*background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,1) 70%);*/
}

.product-card:hover {
  background-color: rgba(255, 255, 255, 0.6);

}

.top-card-info p {
  color: white !important
}

.avega small {
  font-size: 12px
}

.dark-card {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.one-edge-shadow {
  -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.324);
  -moz-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.324);
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.324);
}

.hp-th {
  border-bottom: 4px solid #00ff00 !important
}

.crit-th {
  border-bottom: 4px solid #fff500 !important
}

.spd-th {
  border-bottom: 4px solid #c838f2 !important
}

.ap-th {
  border-bottom: 4px solid #ff0000 !important
}

.def-th {
  border-bottom: 4px solid #00edda !important
}

.stats-table {
  --bs-table-color: white;
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  width: 100%;
  margin-bottom: 0;
  color: white;
  vertical-align: top;
  border-color: transparent;
  font-family: 'avega';
}

.stats-table> :not(caption)>*>* {
  padding: 0.15rem 0.15rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 3px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-spacing: 5px !important;

}

/*  FILTER  SECTION  */
.filter-counter {
  color: black;
  background-color: #ffef00;
  border-radius: 10px;
  font-size: 12;
  font-weight: 900;
  font-style: italic;
  text-align: center;
  padding: 0.1rem 0.3rem;
}

.filters-bar label {
  margin-right: 10px;
  color: white;
  padding: 5px;
}

.dropdown-toggle {
  border-radius: 0;
}

.dropdown {
  border-radius: 0;
}

.exo-select {
  max-width: 200px !important;
  display: block;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  background-color: rgba(0, 0, 0, 0);
  /*padding: 0.28rem 2.25rem 0.28rem 0.75rem;*/
  padding: 0.28rem 0.75rem 0.28rem 0.75rem;
  background-image: none; /*url(../src/images/down-select.svg);*/
  background-repeat: no-repeat;
  background-position: right -1px center;
  background-size: contain;
  border: 1px solid #ced4da;
  border-radius: 0m;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  float: right;
}

.exo-select:hover {
  /*  background: rgba(255, 255, 2550, 0.3) url(../src/images/down-select-yellow.svg) no-repeat;
    background-position: right -1px center;
  background-size: contain;*/
  background-color: rgba(0, 0, 0, 0.3);
  background-image: none; /*url(../src/images/down-select.svg);*/
  background-repeat: no-repeat;
  background-position: right -1px center;
  background-size: contain;

}
.exobot-shadow {background: url(../src/images/exobot-card-shadow.png);
background-size: 95% 120%;
background-position:  center bottom;
background-repeat: no-repeat;
}

.widget-filter-search {
  border-radius: 0 !important;
  background-color: rgba(255, 255, 255, 0.2);
  border: 0;
}

.fa-search.translate-middle-y {
  transform: translateY(70%) !important;
}

input.widget-filter-search::placeholder {
  color: white;
}

input.widget-filter-search:focus {
  background-color: rgba(255, 255, 255, 0.4);
  border: 0;
}

.accordion {

  --bs-accordion-btn-icon: url(../src/images/down-arrow.svg);
  --bs-accordion-btn-active-icon: url(../src/images/down-arrow.svg);
}

/*Double Range selector*/
.range-slider-ui {
  height: .1875rem;
  margin: 3.5rem 1rem;
  border: 0;
  background-color: #eceff6;
  box-shadow: none
}

.range-slider-ui .noUi-connect {
  background-color: #e800ff;
}

.range-slider-ui .noUi-handle {
  top: 50%;
  width: 1.375rem;
  height: 1.375rem;
  margin-top: -0.6875rem;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 .125rem .5625rem -0.125rem rgba(0, 0, 0, .25);
  background: #ffed00;
}

.range-slider-ui .noUi-handle::before,
.range-slider-ui .noUi-handle::after {
  display: none
}

.range-slider-ui .noUi-handle:focus {
  outline: none
}

.range-slider-ui .noUi-marker-normal {
  display: none
}

.range-slider-ui .noUi-marker-horizontal.noUi-marker {
  width: 1px;
  background-color: #d0dae4
}

.range-slider-ui .noUi-marker-horizontal.noUi-marker-large {
  height: .75rem
}

.range-slider-ui .noUi-value {
  padding-top: .125rem;
  color: rgba(255, 255, 255, 0.5);
  font-size: .8125rem
}

.range-slider-ui .noUi-tooltip {
  padding: .25rem .5rem;
  border: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: .75rem;
  line-height: 1.2;
  border-radius: .25rem
}

html:not([dir=rtl]) .range-slider-ui.noUi-horizontal .noUi-handle {
  right: -0.6875rem
}

/**/
.accordion-item {
  color: white;
  background-color: transparent;
  border: 0;
}

.accordion-button {
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) 0;
  font-size: 1rem;
  color: white;
  text-align: left;
  background-color: TRANSPARENT;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  BORDER-BOTTOM: 1PX SOLID WHITE;
}

.accordion-button:not(.collapsed) {
  color: WHITE;
  background-color: TRANSPARENT;
  box-shadow: NONE;
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) 0;
}

/* END OF FILTERS BAR */


.exo-select>option:nth-child(1) {
  font-weight: 600;
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: 0;
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: rgba(255, 255, 255, 0.1);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}




/*  PAGINATION  */
.pagination {
  font-family: 'avega';
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: white;
  --bs-pagination-bg: transparent;
  --bs-pagination-border-width: 0;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: #03223a;
  --bs-pagination-hover-bg: rgba(255, 255, 255, 0.2);
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
  margin: 20px 0;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}

.page-link.active,
.active>.page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled,
.disabled>.page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: yellow;

}

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: yellow;
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem;
}

/* FOOTER */
.download-icons a i {color:white; font-size: 32px;}
.download-icons a:hover i {color:var(--bs-pink)}


.slanted-div {
  position: relative;
  padding: 100px 0;
  background: black;
  overflow: visible;
  z-index: 1;
}

footer .menu a {
  color: white;
  text-decoration: none;
}

footer ul {
  padding-left: 0;
}

footer ul li {
  list-style: none;
  list-style-position: outside;
}


/* where the magic happens */
.slanted-div:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  transform-origin: left top;
  transform: skewY(-3deg);
}

.slanted-div:after {
  bottom: 0;
  transform-origin: left bottom;
  transform: skewY(3deg);
}

/* EXOBOT SINGLE PROFILE*/
.sticky-element {
  position: sticky;
}

.exobot-profile {
  background-color: rgba(0, 0, 0, .25);
}

.profile-title {
  font-family: 'avega';
  font-weight: 800;
}

#back-button {
  padding: 0.7rem 1.5rem 0.7rem 0.7rem;
  background-image: url(../src/images/back-btn.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  color: white;
  text-decoration: none;
  transition: padding .15s ease-in-out;

}

#back-button:hover {
  padding: 0.8rem 1.65rem 0.8rem 0.8rem;
  background-image: url(../src/images/back-btn-blue.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  color: white;
  text-decoration: none;
  transition: padding .15s ease-in-out;
}

.price-exos {
  font-size: 32px;
  font-weight: 800;
}

.single-buy-btn {
  background-color: var(--bs-yellow);
  border-color: var(--bs-yellow);
  color: black;
  transition: transform .45s ease-in-out;
  padding: 0.75rem 1.55rem;
  font-size: 1.55rem;
  border-radius: 0.75rem;
}

.single-buy-btn:hover {
  background-color: black;
  border-color: black;
  color: white;
  transform: scale(105%);
  transition: transform .45s ease-in-out;
}

.single-buy-btn:before {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;

  box-shadow: -8px 8px 1px rgba(0, 0, 0, 0.7);
  -webkit-transform: rotate(10deg);
  -moz-transform: rotate(10deg);
  -o-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
}

.more-info {
  opacity: 0.5;
}

.parts-row {
  background-color: rgba(0, 0, 0, 0.3);
  font-family: 'avega';
  margin-bottom: 5px;
  padding: 0.4rem;
}

.parts-label {
  color: var(--bs-blue);
  text-transform: uppercase;
}

.profile-icons {
  width: 72px;
  margin-bottom: 10px;
  text-align: left;
  float: left;
}

h3.parts {
  -webkit-text-stroke: 2px black;
  text-shadow: 3px 3px black;
  letter-spacing: -0.1rem;
  font-family: Exo;
  font-style: italic;
  font-weight: 900;
  margin-bottom: 20px;
  margin-top: 10px;
  text-transform: uppercase;
}
.single-core {text-transform: uppercase;font-weight: 800; margin-top: 5px;}

td {
  font-family: Exo, sans-serif;
  border-bottom: 3px solid;
}

th {
  border-bottom: 1px solid white !important;
}

/*Table Even Columns Styles*/
.stats-table td:nth-child(even),
.stats-table th:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.3);
}

/* LOGIN TOP BUTTON */
.exo-dropdown {
  border-radius: 0;
  background-color: rgba(147, 0, 161, 0.9);
  color: white;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 56%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 96%);
}

.exo-dropdown a,
.exo-dropdown a:hover {
  color: white;
  font-weight: 600;
}

.exo-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.btn.show,
.btn:active {
  color: white;
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.exo-dropdown .dropdown-divider {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  opacity: 1;
}

.dropdown-item i {
  margin-right: 0.7rem;
}

/* FORMS */
.form-control {
  border-radius: 0;
  background-color: rgba(36, 36, 202, 0.8);
  border: none
}

input:focus {
  background: rgba(255, 255, 255, 0.9) !important;
}

.login-btn-form input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

a.forgot {
  color: #00edda;
  font-size: 14px;
  margin-top: 0;
}

/* SIGN UP PAGE*/
.signin-full-form input::placeholder {
  color: rgba(255, 255, 255, 0.324);
}

.signin-full-form input {
  margin-bottom: 1rem;
  padding: .575rem .75rem;
  color: white;
}

.signin-full-form input:focus,
.signin-full-form input:active {
  background-color: rgba(255, 255, 255, 0.2) !important;
  border-color: yellow !important;
  color: white;
}

.signin-full-form a {
  color: #00edda;

}

.sign-up-msg {
  display: none;
}

/*LANGUAGE SELECTOR*/
.language-picker {
  background: transparent;
  border: transparent;
  font-family: 'Exo';
}

.language-picker:hover,
.language-picker:focus,
.language-picker:active {
  background: rgba(255, 255, 255, 0.2)
}

.dropdown-menu {
  border-radius: 0;
}


/* DASHBOARD */
.exo-tabs .nav-link {
  font-size: 20px;
  color: white;
  padding: 0.5rem 3rem;
  border: 0;
}

.exo-tabs .nav-link button {
  border-bottom: 1px solid white;
}

.exo-tabs .nav-link:hover {
  border: 0;
  border-bottom: 4px var(--bs-pink) solid;
}

.exo-tabs .nav-link.active {
  background: transparent;
  border: 0;
  border-bottom: 4px yellow solid;
  font-weight: 900;
  color: white;
  text-transform: uppercase;
}

.modal-close-btn {
  background: none !important;
  border: none !important;
}

.modal-close-btn i {
  font-size: 32px;
}

.modal-content {
  border-radius: 0 !important;
}

/* BUTTONS */
.yellow-btn {
  background-color: #ffed00;
  border-color: #ffed00;
  color: #000;
}

.red-btn {
  background-color: var(--bs-red);
  border-color: var(--bs-red);
}

.exos-balance {
  background: rgba(0, 0, 0, 0.3);
  width: 300px;
  padding: 2rem;
  text-align: center;
}

.transparent-btn {
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.transparent-btn:active,
.transparent-btn:hover,
.transparent-btn:focus,
.transparent-btn:link,
.transparent-btn:visited {
  background: none;
  border: none;
}

/* NOTIFICATIONS */
.notifications {background-color: #ffed00;}
.notifications .toast {border-radius: 0; background-color: rgba(255, 255, 255, 0.6);}
.notifications .toast-header {color:black}
.notification-in-process {
  color: white;
  background-color: var(--bs-orange);
}

.notification-completed {
  color: white;
  background-color: rgb(0, 220, 0)
}

.notification-error {
  color: white;
  background-color: #ff0048;
}

.offcanvas-title {
  font-weight: 700;
  color: black;
  font-family: 'avega';
  letter-spacing: -1px;
}
.lh-sm {line-height: 1!important;}
.lh-xsm{line-height: 0.75rem!important;}
.ls-sm{letter-spacing: -0.05em;}
.ls-xsm{letter-spacing: -0.07em;}

/* ### ADJUSTING IMG SIZE DIFFERENT SIZES WHILE IN TESTNET, ERASE THIS ONCE WE'RE OUT OF TEST #### */

@media (min-width: 576px) { .exo-img-list {max-height: 233px;}  }
@media (min-width: 768px) { .exo-img-list {max-height: 204px}  }
@media (min-width: 1200px) { .exo-img-list {max-height: 248px} }
@media (min-width: 1400px) { .exo-img-list {max-height: 291px} }

/* ### END OF ADJUSTING IMG DIFFERENT SIZES WHILE IN TESTNET, ERASE THIS ONCE WE'RE OUT OF TEST #### */


/* CAPSULES */
.top-capsule {display:none}
.capsule-box {border-top: 2px solid #ffed00;}
.zoom {
  transition: transform .2s; /* Animation */
}
.zoom2:hover {transition: transform .2s; transform: scale(1.05) rotate(-3deg)}
.zoom:hover {
  /*transform: scale(1.03);  (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}


.capsule-box:hover {background:#003cff!important}
.capsule-card {background:#0e069d;}
.merge-banner {
  background: url(../src/images/merge-capsule-banner.jpg);
  background-size: cover;
  background-position: center center;
  height: 350px;
  border-top: 1px solid #00dafe;
  border-bottom: 1px solid #00dafe;
  }
  @media (min-width: 200px) { .merge-banner {height: 200px;}  }
  @media (min-width: 768px) { .merge-banner {height: 250px;}  }
  @media (min-width: 1200px) { .merge-banner {height: 350px;} }
  @media (min-width: 1400px) { .merge-banner {height: 350px;} }

  

/*  Nexw list view */
.card {border:none; background: none;}
.card-overlay-bottom {
  overflow: hidden;
  padding-bottom: 1em;
}
.card-bg-scale{
  z-index: 99;
}
.card-bg-scale::after { 
  content:"";
  position:absolute; 
  top:0;
  right:0;
  bottom:0;
  left:0;
  background: inherit;
  transition: all 1s !important;
  z-index: -2;
}
.card-bg-scale:hover::after { 
  transform: scale(1.1);
  transition: all 1s !important;
}
.card-overlay-bottom:before {
  content: "";
  position: absolute;
  height: 50%;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(black));
  background-image: linear-gradient(180deg, transparent, black);
  z-index: 1;
}
.card-img-overlay {z-index: 3; }
.card-overlay-bottom {margin-top:-10px}
.card-img-scale .card-img {
  -webkit-transform: rotate(0) scale(1.5);
	transform: rotate(0) scale(1.5);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
  -webkit-transform-origin: 75% 0%;
  -moz-transform-origin: 75% 0%;
  -o-transform-origin: 75% 0%;
  -ms-transform-origin: 75% 0%;
  transform-origin: 75% 0%;
}
.card-img-scale:hover .card-img {
  -webkit-transform: rotate(-2deg) scale(1.7);
	transform: rotate(-2deg) scale(1.7);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
  -webkit-transform-origin: 75% 0%;
  -moz-transform-origin: 75% 0%;
  -o-transform-origin: 75% 0%;
  -ms-transform-origin: 75% 0%;
  transform-origin: 75% 0%;
}

/*##  INO PAGE  ##*/
.btn {border-radius: 0;}
#ino-logo {    max-height: 150px;}
.ino-pack{border:solid 1px white; background-color: rgba(255, 255, 255, 0.45);}
.ino-pack:hover { background-color: rgba(255, 255, 255, 0.75); border:4px solid white; }
footer {background:black}
.top-footer {min-height: 60px; margin-top: -80px; background:url(../src/images/bg-bottom-border.png); background-size: cover; background-position: top center;}
.ino-bg {background: url(../src/images/blueprint-bg.jpg); background-size:cover; background-position: center;}
.ino-header {background: url(../src/images/ino-header-bg.png); background-size:cover;background-position: bottom center; background-repeat: no-repeat;}
.ino-header-txt {z-index:999; font-size:120px; line-height: 90px!important;letter-spacing: -0.5rem; color:white; font-family: avega, sans-serif;font-style: italic; text-shadow:  2px 2px #000, 4px 4px #FFFF4E;}
.out-text-bw { text-shadow:  2px 0 #1B1B56, -2px 0 #1B1B56, 0 2px #1B1B56, 0 -2px #1B1B56,1px 1px #1B1B56, -1px -1px #1B1B56, 1px -1px #1B1B56, -1px 1px #1B1B56,3px 4px #1B1B56}
.flying-nfts {background-image: url(../src/images/flying-exobots-nfts2.png); overflow: visible; background-position: bottom right; background-size:600px; background-repeat: no-repeat; margin-top:-120px}
.cb-float{animation-name: cbfloat;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;}
@keyframes cbfloat {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}
.overlap {margin-right: -250px; z-index:99999}
.dark-blue {color: #1B1B56!important;}
p.dark-blue  {opacity: 0.4!important ;}

@media (max-width:1400px) {
  .flying-nfts {background-image: url(../src/images/flying-exobots-nfts2.png); overflow: visible; background-position: top right; background-size:contain; background-repeat: no-repeat; margin-top:-120px}
  .overlap {margin-right: -150px; z-index:99999}

}
@media (max-width:1200px) {
  .flying-nfts {background-image: url(../src/images/flying-exobots-nfts2.png); overflow: visible; background-position: bottom right; background-size:contain; margin-top:-80px; margin-bottom:40px}
  .overlap {margin-right: -200px; z-index:99999}
  #title-section{z-index: 9999999;}
}

@media (max-width:990px) {
  .overlap {margin-right:150px; z-index:99999}
  .flying-nfts {background-image: url(../src/images/flying-exobots-nfts2.png); overflow: visible; background-position: top right; background-size:500px; margin-top:-80px; margin-bottom:40px}
  .ino-header-txt {font-size: 120px;}
}

@media (max-width:768px) {
  .flying-nfts {background-size:300px; margin-top:-80px; margin-bottom:40px}
}

.beating {
	animation: beat .55s infinite alternate;
	transform-origin: center;
}

/* Heart beat animation */
@keyframes beat{
	to { transform: scale(1.05); }
}

.bar {
  margin: 20px auto;
  width: 100%;
  height: 6px;
  background-color: #00F9FF;
}
.load-10 .bar {
  animation: loadingJ 2s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}
@keyframes loadingJ {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 0);
    background-color: #E51FB2;
    width: 25px;
  }
}

/*  CLAIM PAGE */
.claim {min-height: 70%;}
.claim-page {background-image: url(../src/images/city-background.jpg); background-repeat: no-repeat; background-size: cover; background-position: center;}
.claim-title {color:#00EDDA; font-weight: 600;}

@media (max-width:768px) {
  .claim-txt.display-2 {font-size: 90px; line-height: 70px!important;}
}
@media (max-width:512px) {
  .claim-txt.display-2 {font-size: 60px; line-height: 50px!important; letter-spacing: -2px;}
  .countdown {font-size:2.5em!important}
  #ino-logo {    max-height: 100px;}

}

/*NEW STRUCTURE*/
.exobot {position:fixed;}
#logo {height: 150px; width: auto;}
.top-header {min-height: 60px; margin-top: -80px; background:url(../src/images/bg-top-border-blue.png); background-size: cover; background-position: top center; }
.city-header {background: url(../src/images/city-background.jpg); background-size:cover; background-position: top center; background-repeat: no-repeat;}
.top-container {margin-top: -150px;
  padding-top: 150px;min-height: 300px;}
.watercolor-top-blue {background-image: url(../src/images/bg-top-border-blue-slanted-light.png); background-position: bottom center; background-size: cover; height:224px; margin-top: -224px;
}
.blueprint {background-image: url(../src/images/blueprint-bg-light.jpg); background-size:contain;background-position: top center;}
#exo-filters {/*background: rgba(36,36,202,0.7);*/
  padding: 20px; border-bottom: 2px solid white;
  }
  .outline-2 {text-shadow:  2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000,1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000,6px 8px #000}

  @media (max-width:1200px) {

      .display-1 {font-size: 4rem;}
      .display-3 {    font-size: 3rem;}
      .cards .col-3 {width: 50%;}
  }
  @media (max-width:990px) {
    .display-1 {font-size: 3rem;}
    .display-3 {    font-size: 2rem;}
    .exobot {position: relative;}
    .exobot-picture {text-align: center;}
    .btn-group.operations {width: 100%;}
    .center-lg {margin:0 auto;}

}

.scroll {
  position: fixed;
  width: 625px;
  margin-top: -250px;
}

.scroll-stop {
  width: 625px;
}
@media (max-width:768px) {

  .display-1 {font-size: 5rem;}
  .display-3 {    font-size: 3rem; text-align: center;}
  .top-container {margin-top: -200px;
    padding-top: 250px;}
}

@media (max-width:512px) {

#logo { height:120px; margin: 0 auto;}
}
.page-name {-webkit-text-stroke: 2px black;
  text-shadow: 4px 4px black;
  letter-spacing: -0.3rem;
}
.exobot-profile {background: none;}
/*.cards img {max-width: 45%; float: left; margin-right: 10px;}*/

/* EXOBOT LEVEL SHIELD*/
.exobot-level {
  width: 94px;
  height: 120px;
  padding: 28px 1.5rem 0.7rem 0.7rem;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
  font-family: Exo;
  font-weight: 900;
  font-style: italic;
  -webkit-text-stroke: 2px black;
  text-shadow: 3px 3px black;
  letter-spacing: -0.2rem;
  font-size: 40px;
}
.exobot-level-card {
  float: right!important;
  position: absolute;
  color: white;
  width: 72px;
  height: 90px;
  right: 10px;
  padding: 22px 1.5rem 0.7rem 0.7rem;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
  font-family: Exo;
  font-weight: 900;
  font-style: italic;
  -webkit-text-stroke: 2px black;
  text-shadow: 3px 3px black;
  letter-spacing: -0.2rem;
  font-size: 32px;
  bottom: 80px;
}
.rotate-neg{transform: rotate(-3deg);}
.rotate-pos{transform: rotate(3deg);}
/* SPACINGS */
.mt-n3 {margin-top: -2rem;}
/* RARITY EXOBOTS*/
.rare0-bg {background-color:#0070dd!important;}
.rare1-bg {background-color:#a335ee!important;}
.rare2-bg {background-color:#ff8000!important;}

/* LEADERBOARD */
.leaders-table{background-color: rgba(0, 144, 255, .65); }
.leaders-table table {counter-reset: rowNumber;}
.leaders-table tr::before {
  display:table-cell;
  counter-increment: rowNumber;
  content: counter(rowNumber) " ";
  padding-right: 0.3em;
  text-align: middle;
  font-family: Avega;
  border-bottom: 1px solid white;
  padding-top: 10px;
}
.leaders-table thead{line-height:3em;}
.lights-header {background: url(../src/images/lights-background.jpg); background-size:cover; background-position: top center; background-repeat: no-repeat;}
.btn-link{color: var(--bs-yellow);}
.btn-link:hover{color: var(--bs-blue);}
.fa-fade {animation-duration: var(--fa-animation-duration,2s);
}

